<template>
  <v-container>
    <v-card>
      <v-toolbar
        dark
        dense
        color="orange"
      >
        <v-col cols="1">
          <v-avatar
            tile
            dense
            size="55"
            color="orange lighten-2"
            class="avatar rounded-lg"
          >
            <v-icon
              color="white"
              x-large
              class="mt-1"
            >
              mdi-briefcase
            </v-icon>
          </v-avatar>
        </v-col>
        <h3> Catálogo de Proyectos</h3>
        <v-spacer />
        <v-col cols="3">
          <v-text-field
            v-model="search"
            class="mx-2"
            solo-inverted
            dense
            dark
            color="orange"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
          />
        </v-col>
        <v-btn
          small
          class="ma-4"
          color="success"
          @click="consultar"
        >
          Consultar <v-icon class="ml-1">
            mdi-update
          </v-icon>
        </v-btn>
        <v-btn
          align="right"
          color="deep-orange"
          dark
          small
          class="ma-2"
          :to="{ name: 'proyecto' }"
        >
          Nuevo <v-icon class="ml-1">
            mdi-pencil
          </v-icon>
        </v-btn>
        <!-- EXCEL -->
        <v-btn
          align="right"
          class="ma-4"
          small
          color="green"
          dark
        >
          <download-excel
            :data="proyectos"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="CatProyectos.xls"
          >
            Excel
            <v-icon class="ma-1">
              mdi-download
            </v-icon>
          </download-excel>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col>
        <v-card elevation="10">
          <v-subheader class="">
            <h2 class="ma-4 pt-2 orange--text">
              Proyectos
            </h2>
            <v-spacer />
          </v-subheader>

          <v-row>
            <v-col>
              <v-data-table
                dense
                :loading="loading"
                heigth="400"
                :headers="headers"
                :items="proyectos"
                :search="search"
              >
                <template v-slot:item="{ item }">
                  <tr @dblclick="editar(item)">
                    <!-- <td>{{ item.completo }}</td> -->
                  
                    <td>{{ item.idproyecto }}</td>
                    <td>{{ item.inicio }}</td>
                    <td>{{ item.meta }}</td>
                    <!-- <td>{{ item.fecharango }}</td> -->
                    <td>{{ item.nomproy }}</td>
                    <td>{{ item.numcli }}</td>
                    <!-- <td>{{ item.numproy }}</td> -->
                    <td>{{ item.obs }}</td>
                    <td>{{ item.precio }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      search: '',
      proyectos: [],
      loading: false,
      headers: [
        // { text: 'Estatus', value: 'completo' },
        // { text: 'Id cliente', value: 'idciliente' },
        // { text: 'Id Contacto', value: 'idcontacto' },
        { text: 'Id Proyecto', value: 'idproyecto'},
        { text: 'Fecha Inicio', value: 'inicio' },
        { text: 'Fecha Fin', value: 'meta' },
        // { text: 'Rango de fecha', value: 'fecharango' },

        { text: 'Nombre del proyecto', value: 'nomproy' },
        { text: 'Numero de cliente', value: 'numcli' },
        // { text: 'Numero de proyecto', value: 'numproy' },
        { text: 'Observaciones', value: 'obs' },
        { text: 'Precio', value: 'precio' },
  
      ],
      
      json_fields: {
        Estatus: "completo",
        Id: "idproyecto",
        FechaInicio: "inicio",
        FechaFin: "meta",

        NombreProyecto: "nomproy",
        NumCliente: "numcli",
        NumProyecto: "numproy",
        Observaciones: "obs",
        Precio: "precio",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      json_data: [],
    }
  },
  created(){
    this.consultar()
  },

  methods: {
      
      
    consultar(){
      // limpiar
      this.proyectos= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      this.loading = true

      this.$http.get("v2/proyectos.list" ).then(response=>{
          
        if (response.data.error===null){
          this.proyectos = response.data.result

          //this.proyectos.forEach( element => {
          //element.fechastart = element.fechastart.substr(0,10)
          //element.hora = element.fechastart.substr(11,17)
          //})

          console.log("proyectos", this.proyectos)
        }
        this.loading = false
      }).catch(error=>{
        this.loading = false
        console.log(error)
      })
    },

    editar(item){
      console.log("Renglonitem", item)
      this.$router.push({name:'proyecto', params: {proyecto:item}})
    },
  }
    
}

</script>

<style scoped>
.avatar{
  top: -10px;
}
</style>